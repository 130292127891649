main {
  min-height: 80vh;
}

h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

.rating span {
  margin: 0.1rem;
}

/* logo */
.logoHeader {
  width: 150px;
  opacity: 0.8;
}

.logoHeader:hover {
  opacity: 1;
}

.logoFooter {
  width: 70%;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 40px;
}

.carousel a {
  margin: 0 auto;
}

.banner-cr img {
  height: 88vh;
  padding: 0;
  margin: 0;
  border-radius: 0;
  width: 100%;
  object-position: top;
}

.about-us-img {
  max-width: 80%;
  margin: 2rem auto;
  text-align: center;
  align-self: center;
  justify-self: center;
  display: block;
}

/* Marquee  */
.marquee {
  width: 100%;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 30s linear infinite;
}
.marquee img {
  width: 90px;
  min-width: 90px;
  filter: grayscale(100%);
  cursor: pointer;
}
.marquee img:hover {
  filter: grayscale(0%);
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.rounded {
  border-radius: 3px !important;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.r-0 {
  right: 0;
}

.rotate {
  transition: all ease-in-out 0.2s;
}

.rotate:hover {
  transform: rotate(90deg);
}

.disp-flex {
  display: flex;
}

.w100p {
  width: 100%;
}

.img-prod {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: contain;
}

.img-prod-cont {
  width: 100%;
  height: 180px;
  text-align: center;
  overflow: hidden;
}

.social-network a {
  opacity: 0.8 !important;
}

.social-network a:hover {
  text-decoration: none !important;
  opacity: 1 !important;
}

b {
  font-weight: bold !important;
}

.filterSelected {
  text-decoration: underline;
  font-style: italic;
}

.pt-2-4 {
  padding-top: 2.4rem;
}

#productCarousel .row {
  margin-left: 0;
  margin-right: 0;
}

#productCarousel img {
  margin: 80px 0px 30px 0px;
}

#productCarousel .carousel-caption {
  bottom: auto;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  right: 0;
  left: auto;
}

footer a {
  color: #fff;
  opacity: 0.8;
}

footer a:hover {
  color: #fff;
  opacity: 1;
}

.mh-400 {
  min-height: 400px;
}

.mh-210 {
  min-height: 210px;
}

.of-ct {
  object-fit: contain;
}

.CardLinkContainer {
  cursor: pointer;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.CardLinkContainer:hover {
  cursor: pointer;
  box-shadow: 4px 4px 7px #333;
}

.dark-blue {
  color: #43596b;
}

.bold {
  font-weight: bold;
}

.secondary-blue {
  color: #3d7eb8;
}

.no-border {
  border: none !important;
}

.product-carousel-row {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .carousel img {
    height: 250px;
    margin: 90px 0px 20px 0px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
  html,
  body {
    max-width: 100vw !important;
    overflow-x: hidden !important;
  }
  .sm-mt-0 {
    margin-top: 0 !important;
  }
  .product-carousel-row {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .min-w-320 {
    min-width: 320px;
  }
}
@media only screen and (min-width: 576px) {
  .r-sm-2 {
    right: 0.5rem !important;
  }
}
